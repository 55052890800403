import {useEffect, useState} from 'react';
import {Alert, Button, Icon} from '@ezcater/tapas';
import {faMapMarkerAlt} from '@fortawesome/pro-solid-svg-icons';
import {Form, Formik, type FormikErrors} from 'formik';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import AddressSearchAutocomplete from '@/components/AddressSearchAutocomplete';
import {type GeocodedAddressResult} from '@/graphql/types';
import {useGoogleMapsGeocode} from '@/hooks/useGoogleMapsGeocode';
import useScrollDepth from '@/hooks/useScrollDepth';
import useTracking from '@/hooks/useTracking';
import {SEARCH_NEW_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {
  geocodedAddressResultToStartNewSearchAddressParams,
  geocodedAddressResultToString,
} from '@/utils/geocodedAddressResult';
import {geocodedAddressToStartNewSearchParams} from '@/utils/googleAutocomplete';

type StartOrderFormProps = {
  baseId: string;
  lastSearchedAddress?: string | null;
};

type FormValues = {
  addressString: string;
  geocodedAddressResult: GeocodedAddressResult | null;
  googlePlace: google.maps.places.AutocompletePrediction | null;
};

const StartOrderForm: React.FC<StartOrderFormProps> = ({baseId, lastSearchedAddress}) => {
  const router = useRouter();
  const {t} = useTranslation('home');
  const {trackClick} = useTracking();
  const [isLoading, setIsLoading] = useState(false);
  const {geocodeAutocompletePrediction} = useGoogleMapsGeocode();
  const {deleteScrollDepth} = useScrollDepth();

  useEffect(() => {
    const routeChangeStart = () => setIsLoading(true);
    const routeChangeCompleteOrError = () => setIsLoading(false);
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeCompleteOrError);
    router.events.on('routeChangeError', routeChangeCompleteOrError);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeCompleteOrError);
      router.events.off('routeChangeError', routeChangeCompleteOrError);
    };
  }, [router.events]);

  const getQueryStringPromise = async (
    prediction: google.maps.places.AutocompletePrediction | null,
  ): Promise<Record<string, any>> => {
    if (prediction) {
      return new Promise((resolve, reject) => {
        geocodeAutocompletePrediction(prediction)
          .then(geocodedAddress => {
            if (geocodedAddress && geocodedAddress.length > 0) {
              resolve(geocodedAddressToStartNewSearchParams(geocodedAddress[0]));
            } else {
              reject(new Error('No geocoded address found'));
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    } else {
      return Promise.resolve({addressString: ''});
    }
  };

  return (
    <Formik<FormValues>
      data-role="start-order-form"
      data-tracking-submit-element-name={`${baseId}-address-submit-click`}
      data-vars-event-scrollable={`${baseId}-address-search-bar`}
      initialValues={{
        addressString: lastSearchedAddress || '',
        geocodedAddressResult: null,
        googlePlace: null,
      }}
      validate={values => {
        const errors: FormikErrors<FormValues> = {};
        if (!values.googlePlace && !values.addressString) errors.addressString = t('formError');
        return errors;
      }}
      validateOnChange={false}
      onSubmit={async (values: FormValues) => {
        trackClick('home-start-order-address-submit-click');
        let queryStringPromise: Promise<Record<string, any> | undefined>;
        if (values.googlePlace) {
          queryStringPromise = getQueryStringPromise(values.googlePlace);
        } else if (
          values.geocodedAddressResult &&
          geocodedAddressResultToString(values.geocodedAddressResult) === values.addressString
        ) {
          queryStringPromise = Promise.resolve(
            geocodedAddressResultToStartNewSearchAddressParams(values.geocodedAddressResult),
          );
        } else {
          queryStringPromise = Promise.resolve({addressString: values.addressString});
        }

        const addressParams = await queryStringPromise;

        // delete the scroll depth stored for the search page if the user is starting a brand new search
        deleteScrollDepth();

        router.push(
          compilePath(SEARCH_NEW_PATH, {}, addressParams, {
            skipEmptyString: true,
            skipNull: true,
          }),
        );
      }}
    >
      {({errors, isSubmitting}) => (
        <Form>
          <label className="hidden" htmlFor={`${baseId}__address_string`}>
            {t('addressInputLabel')}
          </label>

          <div className="flex flex-col gap-2 pb-10">
            <div className="flex flex-col gap-2 px-4 min-[640px]:flex-row min-[640px]:gap-0 min-[640px]:pb-0 tablet:px-0">
              <div className="flex-1 rounded bg-white min-[640px]:min-w-80 min-[640px]:rounded-r-none">
                <AddressSearchAutocomplete
                  id="start-new-order-event-address"
                  geocodedAddressResultName="geocodedAddressResult"
                  googlePlaceName="googlePlace"
                  inputName="addressString"
                  savedAddressName="savedAddress"
                  shouldSubmitOnSelect
                  slotProps={{
                    input: {
                      className:
                        'border-none h-full tablet:text-lg h-12 rounded-md min-[640px]:rounded-r-none',
                      placeholder: t('addressInputLabel'),
                      prefix: <Icon className="text-red-100" icon={faMapMarkerAlt} size="small" />,
                    },
                    listbox: {className: 'rounded-t-none -mt-1.5 min-w-0 p-0'},
                  }}
                />
              </div>

              <Button
                aria-label={t('searchButtonAriaLabel') || t('searchButtonLabel')}
                className={twJoin(
                  'h-12 bg-guava-300 hover:bg-guava-400 active:bg-guava-400 min-[640px]:w-32 min-[640px]:flex-none min-[640px]:rounded-none min-[640px]:rounded-r',
                )}
                loading={isSubmitting || isLoading}
                type="submit"
              >
                {t('searchButtonLabel')}
              </Button>
            </div>

            {errors.addressString && (
              <div className="px-4 tablet:px-0">
                <Alert className="leading-tight" variant="error">
                  {errors.addressString}
                </Alert>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StartOrderForm;
