
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useMemo } from 'react';
import { type GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import ezCaterLogo from '@/assets/images/brand/ezcater-vertical-lg.png';
import heroAvif from '@/assets/images/home/hero-bg.avif';
import heroJpg from '@/assets/images/home/hero-bg.jpg';
import heroWebp from '@/assets/images/home/hero-bg.webp';
import heroSmAvif from '@/assets/images/home/hero-bg-sm.avif';
import heroSmJpg from '@/assets/images/home/hero-bg-sm.jpg';
import heroSmWebp from '@/assets/images/home/hero-bg-sm.webp';
import { type LoggedOutHomePageQuery, LoggedOutHomePageQueryDocument } from '@/graphql/types';
import initServerSideRequest from '@/pageApi/initServerSideRequest';
import HomeRoot from '@/pageComponents/home/HomeRoot';
import { START_NEW_ORDER_PATH } from '@/paths';
import { compilePath } from '@/utils';
const { publicRuntimeConfig } = getConfig();
type HomeProps = {
    lastSearchedAddress?: string | null;
    imageSupport: {
        avif?: boolean;
        webp?: boolean;
    };
};
const Home: React.FC<HomeProps> = ({ lastSearchedAddress, imageSupport }) => {
    const { t } = useTranslation('home-contentful');
    const [desktopBannerImage, mobileBannerImage] = useMemo(() => {
        if (imageSupport.avif)
            return [heroAvif, heroSmAvif];
        if (imageSupport.webp)
            return [heroWebp, heroSmWebp];
        return [heroJpg, heroSmJpg];
    }, [imageSupport]);
    return (<>
      <Head>
        <title>{t('name')}</title>
        <meta name="description" content={t('pageDescription')}/>
        {publicRuntimeConfig.facebookDomainVerificationCode && (<meta name="facebook-domain-verification" content={publicRuntimeConfig.facebookDomainVerificationCode}/>)}
        <script type="application/ld+json" dangerouslySetInnerHTML={{
            __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'Corporation',
                name: 'ezCater',
                url: 'https://www.ezcater.com',
                logo: ezCaterLogo.src,
                contactPoint: {
                    '@type': 'ContactPoint',
                    telephone: '(800) 488-1803',
                    contactType: 'customer support',
                },
                address: {
                    '@type': 'PostalAddress',
                    streetAddress: '40 Water St.',
                    addressLocality: 'Boston',
                    addressRegion: 'MA',
                    postalCode: '02109',
                    addressCountry: 'US',
                },
                sameAs: [
                    'https://www.linkedin.com/company/ezcater',
                    'https://www.instagram.com/ezcater/',
                    'https://www.facebook.com/ezCaterInc/',
                    'https://x.com/ezCater',
                    'https://www.tiktok.com/discover/Ezcater',
                    'https://www.youtube.com/c/ezCater',
                    'https://play.google.com/store/apps/details?id=com.ezcater.ezcaterapp',
                    'https://apps.apple.com/us/app/ezcater-business-catering/id1162865183',
                ],
            }),
        }}/>
        <link rel="preload" href={desktopBannerImage.src} as="image" media="(max-width: 767px)"/>
        <link rel="preload" href={mobileBannerImage.src} as="image" media="(min-width: 768px)"/>
      </Head>
      <HomeRoot lastSearchedAddress={lastSearchedAddress} enableTrustPilot={!!publicRuntimeConfig.enableTrustPilot}/>
    </>);
};
const getServerSideProps: GetServerSideProps<StoreNextPageDataProps & HomeProps> = async (ctx) => {
    const { apolloClient, extractAppProps, loadExperiments, loadFeatureFlags } = initServerSideRequest(ctx);
    const { data: userData } = await apolloClient.query<LoggedOutHomePageQuery>({
        query: LoggedOutHomePageQueryDocument,
        errorPolicy: 'all',
    });
    const isLoggedIn = Boolean(userData?.me?.consumerAccount);
    if (isLoggedIn) {
        // redirect to RHP
        return {
            redirect: {
                destination: compilePath(START_NEW_ORDER_PATH),
                permanent: false,
            },
        };
    }
    // Load the feature flags and experiments
    await Promise.all([loadFeatureFlags(), loadExperiments()]);
    return {
        props: {
            ...extractAppProps(),
            lastSearchedAddress: userData?.identity?.lastSearchedAddress || null,
            imageSupport: {
                avif: ctx.req.headers.accept?.includes('image/avif'),
                webp: ctx.req.headers.accept?.includes('image/webp'),
            },
            libertyWebchat: {
                enabled: true,
            },
        },
    };
};
export default Home;

    async function __Next_Translate__getServerSideProps__195fd59aedc__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195fd59aedc__ as getServerSideProps }
  